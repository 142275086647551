import React from 'react';
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";

import { ThemeContext } from '../../context/theme-context';

import Layout from '../../components/layout';
import Footer from '../../components/footer';
import Title from '../../components/title';
import BannerImage from '../../components/bannerImage';
import ContactBlock from '../../components/contactBlock';
import NextCase from '../../components/nextCase';
import IntroBlock from '../../components/introBlock';
import BannerResult from '../../components/bannerResult';
import VideoThumbnail from '../../components/videoThumbnail';
import SimplePhrase from '../../components/simplePhrase';

const CasesPage = ({ location }) => {

    return (

        <Layout bg="white" location={location}>

            <Helmet>
                <script src={withPrefix('wrap.js')} type="text/javascript" />
                <title>ATUO - Cases - Mercado de Terras</title>
            </Helmet>

            <div className="container mx-auto">

                <Title
                    title="Mercado <br /> de Terras"
                    titleColor="#332E2D"
                    description="O valor da terra brasileira por quem ama nosso chão."
                    smallDescription="[tradição, confiança, tecnologia]"
                />

                <BannerImage src={`/mercado-de-terras/mercado_content_one.jpg`} title="Mercado de Terras" />

                <IntroBlock
                    logo={`/mercado-de-terras/mercado-de-terra_logo.svg`}
                    alt="Mercado de Terras"
                    description="Criar soluções inovadoras para o setor imobiliário rural se posicionando como parceiro do campo e especialista em vendas de fazendas, haras, sítios e chácaras em todo o território brasileiro."
                    list={['Estratégia & Inovacão', 'Pesquisa & Inteligência', 'Design de Experiência do Consumidor', 'Cultura, Conteúdo & Social']} />

                <SimplePhrase paddingX={24}>A criação de experiência e posicionamento à altura de quem mais entende do campo.</SimplePhrase>

                <div className="flex justify-center gap-x-32 md:px-24 items-center mt-10 mb-40 flex-col md:flex-row">
                    <div className="md:w-1/2 text-2xl mb-24 md:mb-0">
                        <p>Iniciais destacadas e com uso do elemento chapéu como símbolo do trabalho no campo. <br /><br /> Rebrand de uma marca presente em grandes realizações no agronegócio.</p>
                    </div>

                    <div className="md:w-1/2 text-center">
                        <img src={`/mercado-de-terras/mercado_stamp.svg`} alt="Mercado de Terras" className="inline" />
                    </div>
                </div>

                <div className="md:text-center mx-auto my-60">
                    <p className="text-2xl md:text-4xl font-semibold md:px-56">Uma marca presente em grandes realizações no agronegócio</p>
                    <div className="mt-24 md:mt-20 text-center">
                        <img src={`/mercado-de-terras/mercado_content_three_mobile.svg`} className="w-4/5 md:w-3/5 inline md:hidden " alt="Mercado de Terras" />
                        <img src={`/mercado-de-terras/mercado_content_three.png`} className="w-4/5 md:w-3/5 hidden md:inline" alt="Mercado de Terras" />
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 my-40 md:my-60 gap-x-16">
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-2xl md:text-4xl mb-10 font-semibold">O design de comunicação da marca foi construído centrado no homem do campo</p>

                        <p className="text-xl md:text-2xl">
                            O centro da comunicação e do design é dedicado ao homem do campo, que se dedica de sol a sol para produzir riquezas para o país. <br/><br/>
                            Cores inspiradas na lavoura. O dourado representa o campo de trigo, e o marrom o solo rico em nutrientes. Juntos, os tons compõem a riqueza das terras brasileiras.
                        </p>
                    </div>

                    <div className="mt-40 md:mt-0 flex justify-center items-center">
                        <img src={`/mercado-de-terras/mercado_content_two.png`} alt="Mercado de Terras" />
                    </div>
                </div>

                <div>
                    <img src={`/mercado-de-terras/mercado_content_four.jpg`} alt="Mercado de Terras" />
                </div>

                <SimplePhrase paddingX={52}>A valorização da terra foi evidenciada na cultura da marca.</SimplePhrase>

                <div>
                    <VideoThumbnail src="/mercado-de-terras/mercado_content_five.jpg" alt="Mercado de Terras" videoSrc="https://www.youtube.com/embed/yLyPP9WP_y0" />
                    <p className="mt-6 md:mt-10 text-right  text-xl">[muito prazer, eu sou a terra]</p>
                </div>

                <div className="my-40">
                    <div className="w-full md:w-3/4 mx-auto md:text-center">
                        <p className="text-2xl md:text-4xl mb-10 md:mb-16 font-semibold">Experiência Mobile First</p>
                        <p className="text-xl">Criação de plataforma mobile para receber os usuários que acessam via dispositivos móveis, garantindo assim uma jornada de navegação mais atrativa para o público.</p>
                    </div>

                    <div className="mt-24 md:mt-0">
                        <img src={`/mercado-de-terras/mercado_content_six.png`} alt="Mercado de Terras" />
                    </div>
                </div>

                <div className="mt-40 mb-40 md:my-60 md:text-center">
                    <h3 className="mx-auto text-2xl md:text-4xl font-semibold mb-10 md:px-64">Inovação da forma de apresentar os seus produtos</h3>
                    <p className="text-xl md:px-24">Os produtos passaram a ser apresentados de forma mais assertiva e o Mercado de Terras virou referência no mercado, alcançando relevância, se destacando na primeira página de busca do Google e realizando mais de 5 mil negócios.</p>
                </div>

                <div className="mb-40">
                    <VideoThumbnail src="/mercado-de-terras/mercado_content_seven.jpg" alt="Mercado de Terras" videoSrc="https://www.youtube.com/embed/YE74m5eTdeo" />
                    <p className="text-right text-xl mt-10">[maior valorização para seus produtos]</p>
                </div>

                <SimplePhrase>Hoje a marca ampliou seu brand awareness por meio da presença digital consolidada em diversos meios on-line.</SimplePhrase>
            </div>

            <BannerResult
                bg="#C09F50"
                list={['+ 5 mil negócios realizados', 'Referência no mercado', '1ª página nos principais mecanismos de busca', '+ Presença digital', 'Brand Awareness']} />

            <NextCase
                link="/cases/favori"
                title="Fávori"
                description="O gostinho caseiro da confeitaria todo dia na sua mesa."
                image="/favori-next.png" />

            <ThemeContext.Consumer>
                {cx => (
                    <ContactBlock
                        description="Gostou dos nossos cases? Tem muito mais de onde veio! Clique no botão ao lado e entre em contato conosco. Vamos dar o primeiro passo para sua marca ir além."
                        weather={cx?.cookies?.weather}
                    />
                )}
            </ThemeContext.Consumer>

            <Footer />

        </Layout>
    );
};

export default CasesPage;
