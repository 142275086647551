import React, { useEffect, useState } from 'react';

const VideoModal = ({ src, width, height, handleClose }) => {

    useEffect(() => {
        const keyDownHandler = (e) => {
            e.key === 'Escape' && handleClose(false)
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };

    }, [width]);


    return (
        <div className="video-modal cursor-pointer fixed top-0 left-0 w-screen h-screen z-10" onClick={() => handleClose(false)}>
            <div className="flex justify-center items-center w-full h-full">
                <div className="relative">
                    <iframe
                        className="video-modal-iframe"
                        width={width}
                        height={height}
                        src={`${src}?autoplay=1&rel=0`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                    />
                    {/* <span className="close-iframe absolute top-0 right-0 transform -translate-y-full translate-x-full text-white">X</span> */}
                </div>
            </div>
        </div>
    );
};

export default VideoModal;