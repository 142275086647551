import { TransitionPortal } from 'gatsby-plugin-transition-link';
import React, { useEffect, useRef, useState } from 'react';

import VideoModal from './videoModal';

const VideoThumbnail = (props) => {

    const videoThumbnail = useRef(null);

    const [isClicked, setIsClicked] = useState(false);
    const [size, setSize] = useState({
        width: 560,
        height: 315,
    });

    const resize = () => setSize({
        width: videoThumbnail.current?.clientWidth,
        height: videoThumbnail.current?.clientHeight
    });

    useEffect(() => {
        resize();
        window.addEventListener("resize", resize);
    }, [size.width, isClicked]);

    return (
        <>
            <div className="video-thumbnail cursor-pointer relative overflow-hidden" ref={videoThumbnail}>

                <div
                    className="cursor-pointer"
                    onClick={() => setIsClicked(true)} >
                    <img className="transform transition duration-500 hover:scale-110" src={props.src} alt={props.alt} />
                    <img
                        className="play-icon absolute w-1/5 md:w-1/12 top-1/2 left-1/2 transform -translate-y-2/4 -translate-x-2/4 transition duration-500 opacity-60"
                        src="/Play.svg"
                        alt=""
                    />
                </div>

            </div>
            {
                isClicked && props.videoSrc && (
                    <TransitionPortal>
                        <VideoModal src={props.videoSrc} width={size.width} height={size.height} handleClose={setIsClicked} />
                    </TransitionPortal>
                )
            }
        </>
    );
};

export default VideoThumbnail;